import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const connection = new HubConnectionBuilder()
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    // .withUrl("https://localhost:5001/play")
    .withUrl("https://plump.azurewebsites.net/play")
    .build();

const middleware = (store: any) => {
    connection.on("dispatch", action => {
        store.dispatch({...action, remote: true});
    });

    connection
        .start()
        .catch(function (err) {
            return console.error(err.toString());
        });

    return (next: any) => async (action: any) => {
        if (action.remote) {
            next(action);
        } else {
            await connection.invoke("dispatch", action);
        }
    }
};

export const SignalRMiddleware = middleware;