export type Card = {
    rank: CardRank,
    suit: CardSuit
}

export type PlayerState = {
    name: string
    cards: number
    bet?: number
    isReady: boolean
    isCurrent: boolean
    played?: Card
    tricks: number
    isLocal: boolean
}

export type RootState = {
    gameId?: string
    firstRoundStarted: boolean

    hand: Card[]
    isAdmin: boolean
    bet?: number
    name?: string
    isCurrent: boolean

    trump?: CardSuit
    players: PlayerState[]
    scores: Score[]
};

export type Score = {
    cardCount: number
    scores: number[]
};

export type Round = {
    cardsPerPlayer: number
    dealer: number
    trump: CardSuit
    bets: number[]
    tricks: number[]
    initialSuit?: CardSuit
}
export enum CardRank {
    Two = 2,
    Three = 3,
    Four = 4,
    Five = 5,
    Six = 6,
    Seven = 7,
    Eight = 8,
    Nine = 9,
    Ten = 10,
    Jack = 11,
    Queen = 12,
    King = 13,
    Ace = 14
}

export enum CardSuit {
    Diamonds = 0,
    Clubs = 1,
    Hearts = 2,
    Spades = 3
}

const initialState: RootState = {
    firstRoundStarted: false,

    gameId: undefined,
    trump: undefined,
    players: [],
    scores: [],

    isAdmin: false,
    hand: [],
    bet: undefined,
    name: undefined,
    isCurrent: false
}

const getScores = (state: RootState): Score[] => {
    if (!state.firstRoundStarted) return state.scores;
    return [...state.scores, { cardCount: state.players[0].cards, scores: state.players.map(p => p.tricks === p.bet ? (p.bet ? 10 + p.bet : 5) : 0) }];
}

export default function rootReducer(state: RootState = initialState, action: any): RootState {
    switch (action.type) {
        case 'ENTER_TABLE':
        case 'ENTER_PLAYER':
            return {
                ...state,
                gameId: action.gameId,
                isAdmin: !!action.isAdmin,
                name: action.playerName
            };
        case 'SET_PARTIAL_STATE':
            return {
                ...state,
                ...action
            }
        case 'PLAYER_JOIN':
            return {
                ...state,
                players: [
                    ...state.players,
                    { name: action.playerName, isLocal: false, cards: 0, isReady: false, isCurrent: false, tricks: 0 }
                ]
            };
        case 'SET_LOCAL_PLAYER':
            return {
                ...state,
                players: state.players.map(p => p.name === action.playerName ? { ...p, isLocal: true } : p)
            }
        case 'SET_PLAYERS':
            return {
                ...state,
                players: action.players
            }
        case 'SET_ACTIVE_PLAYER':
            return {
                ...state,
                isCurrent: state.name === action.playerName,
                players: state.players.map(p => ({...p, isCurrent: p.name === action.playerName}))
            }
        case 'DEAL_CARDS':
            return {
                ...state,
                hand: action.cards,
                bet: undefined
            };
        case 'START_ROUND':
            return {
                ...state,
                scores: getScores(state),
                firstRoundStarted: true,
                trump: action.trump,
                players: state.players.map((p, i) => ({
                    ...p,
                    cards: action.cardsPerPlayer,
                    isCurrent: i === action.startingPlayer,
                    isReady: false,
                    played: undefined,
                    tricks: 0,
                    bet: undefined
                }))
            };
        case 'BET_PLACED':
            return {
                ...state,
                players: state.players.map(p => p.name === action.playerName ? { ...p, isReady: true } : p)
            };
        case 'BET_CONFIRMED':
            return {
                ...state,
                firstRoundStarted: true,
                bet: action.bet
            };
        case 'SET_BETS':
            return {
                ...state,
                players: state.players.map(p => ({ ...p, bet: action[p.name] }))
            };
        case 'PLAY_CARD':
            return {
                ...state,
                hand: state.hand.filter(c => c.rank !== action.card.rank || c.suit !== action.card.suit),
                players: state.players.map(p => p.name === action.playerName ? { ...p, played: action.card } : p)
            };
        case 'WIN_TRICK':
            return {
                ...state,
                players: state.players.map(p => ({
                    ...p,
                    played: undefined,
                    isCurrent: p.name === action.playerName,
                    tricks: p.tricks + (p.name === action.playerName ? 1 : 0)
                }))
            };
    }
    return state;
}
