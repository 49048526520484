import { range } from "lodash";
import React, { CSSProperties } from "react";
import CardBack from "./CardBack";

interface TrickProps {
    count: number
};

const Trick = ({ count }: TrickProps) => {
    const style: CSSProperties = {
        display: undefined && 'block',
        height: '150px',
        width: '100%'
    }

    return <div style={style}>
        {range(count).map(x => <div style={{position: 'absolute', left: x * 20 }}><CardBack /></div>)}
    </div>
};

export default Trick;