import 'cardsJS/cards.css';
import './App.css';
import { useSelector } from 'react-redux';
import { RootState } from './reducers';
import React from 'react';
import Lobby from './Lobby';
import { BrowserView, MobileView } from 'react-device-detect';
import Table from './Table';
import Hand from './Hand';

function App() {
  const gameId = useSelector<RootState, string | undefined>(state => state.gameId);

  if (!gameId) {
    return <Lobby />
  }

  return (
    <div className="App">
      <BrowserView style={{width: '90%'}}><Table /></BrowserView>
      <MobileView><Hand /></MobileView>
    </div>
  );
}

export default App;
