import './Card.css';

import { useState } from "react";
import { Card as CardType, CardRank, CardSuit } from "./reducers";


type CardProps = {
    card: CardType,
    isSelected?: boolean,
    handleClick?: () => void
};

const ranks = {
    [CardRank.Two]: 2,
    [CardRank.Three]: 3,
    [CardRank.Four]: 4,
    [CardRank.Five]: 5,
    [CardRank.Six]: 6,
    [CardRank.Seven]: 7,
    [CardRank.Eight]: 8,
    [CardRank.Nine]: 9,
    [CardRank.Ten]: 10,
    [CardRank.Jack]: 'J',
    [CardRank.Queen]: 'Q',
    [CardRank.King]: 'K',
    [CardRank.Ace]: 'A'
};

const suits = {
    [CardSuit.Diamonds]: 'D',
    [CardSuit.Clubs]: 'C',
    [CardSuit.Hearts]: 'H',
    [CardSuit.Spades]: 'S'
}

function Card({ card: { rank, suit }, handleClick, isSelected = false } : CardProps) {
    const [img, setImg] = useState<any>();
    import(`cardsJS/cards/${ranks[rank]}${suits[suit]}.svg`).then(image => setImg(image.default));
  
    return (img && <img alt={`${rank} of ${suit}`} className={isSelected ? 'card selected' : 'card'} src={img} onClick={handleClick} />) || null;
}

export default Card;