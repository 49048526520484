import React, { CSSProperties } from "react";
import Card from "./Card";
import { PlayerState } from "./reducers";
import Trick from "./Trick";

interface PlayerProps {
    player: PlayerState,
    rotate: number
};

const Player = ({ player, rotate }: PlayerProps) => {
    const style: CSSProperties = {
        color: player.isReady ? 'green' : undefined,
        fontWeight: player.isCurrent ? 'bold' : 'normal',
        fontStyle: player.isCurrent ? 'italic' : 'normal',
        transform: `rotate(${player.isLocal ? rotate : -rotate}deg)`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '20%'
    };

    const playedCard = <div key='playedCard' style={{ marginBottom: player.isLocal ? 'auto' : undefined, marginTop: player.isLocal ? undefined : 'auto' }}>
        {player.played && <Card card={player.played} />}
    </div>;
    const tricks = <Trick key='tricks' count={player.tricks} />
    const playerName = <div key='playerName' style={{ marginTop: player.isLocal ? 'auto' : undefined, marginBottom: player.isLocal ? undefined : 'auto' }}>
        {player.name}
    </div>;

    return <div style={style}>
        {player.isLocal ? [playedCard, tricks, playerName] : [playerName, tricks, playedCard]}
    </div>
};

export default Player;