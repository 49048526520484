import { range } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "./Card";
import { Card as CardType, RootState } from "./reducers";

const Hand = () => {
    const isAdmin = useSelector<RootState, boolean>(state => state.isAdmin);
    const isStarted = useSelector<RootState, boolean>(state => state.firstRoundStarted);
    const hand = useSelector<RootState, CardType[]>(state => state.hand);
    const bet = useSelector<RootState, number | undefined>(state => state.bet);
    const isActive = useSelector<RootState, boolean>(state => state.isCurrent);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isActive) {
            navigator.vibrate && navigator.vibrate(200);
        }
    }, [isActive]);

    const [betTemp, setBetTemp] = useState(0);
    const [selectedCard, setSelectedCard] = useState<CardType | null>(null);

    const handleStart = () => dispatch({ type: "START_GAME" });
    const handlePlaceBet = () => {
        dispatch({ type: "PLACE_BET", bet: betTemp });
        setBetTemp(0);
    }
    const handleClick = (card: CardType) => {
        if (selectedCard === card) {
            dispatch({type: "PLAY_CARD", card});
        } else {
            setSelectedCard(card);
        }
    }

    return hand.length
        ? <div>
            {hand.map(card => (<Card card={card} handleClick={() => handleClick(card)} isSelected={card === selectedCard} />))}
            {typeof bet !== 'number' && (
                <>
                    <div>
                        <select value={betTemp} onChange={e => setBetTemp(Number(e.target.selectedOptions[0].value))}>
                            {range(hand.length + 1).map(n => <option value={n}>{n}</option>)}
                        </select>
                        <button onClick={handlePlaceBet}>Ok</button>
                    </div>
                </>)}
        </div>
        : (isAdmin && !isStarted) ? <button onClick={handleStart}>Start</button> : <>Please Wait...</>;
};

export default Hand;