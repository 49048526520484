import { groupBy, reverse, sum } from "lodash";
import React, { CSSProperties } from "react";
import { useSelector } from "react-redux";
import Player from "./Player";
import { CardSuit, PlayerState, RootState, Score } from "./reducers";

const Table = () => {
    const players = useSelector<RootState, PlayerState[]>(state => state.players);
    const trump = useSelector<RootState, CardSuit | undefined>(state => state.trump);
    const scores = useSelector<RootState, Score[]>(state => state.scores);

    const tableStyle: CSSProperties = { fontSize: '20px', border: "1px solid white", borderCollapse: "collapse" };

    let { true: localPlayers, false: remotePlayers } = groupBy(players, p => p.isLocal);
    localPlayers = localPlayers || [];
    remotePlayers = remotePlayers || [];

    const trumpSymbols = {
        [CardSuit.Diamonds]: '\u2666',
        [CardSuit.Clubs]: '\u2663',
        [CardSuit.Hearts]: '\u2665',
        [CardSuit.Spades]: '\u2660'
    }

    const betsTableRow = players.length && players.every(p => typeof p.bet === 'number') ?
        <tr style={{ ...tableStyle, fontStyle: 'italic' }}>
            <td style={{ ...tableStyle, fontWeight: 'bold' }}>
                {players[0].cards}
            </td>
            {players.map(p => <td style={tableStyle}>{p.bet}</td>)}
        </tr> : undefined;

    return (<div>
        <table style={{ ...tableStyle, position: 'absolute', left: 20, top: 20 }}>
            <thead>
                <tr>
                    <th />
                    {players.map(p => <th key={p.name} style={{ ...tableStyle, fontWeight: 'bold' }}>{p.name[0]}</th>)}
                </tr>
            </thead>
            <tbody>
                {scores.map((s, i) => <tr key={i}>
                    <td style={{ ...tableStyle, fontWeight: 'bold' }}>{s.cardCount}</td>
                    {s.scores.map(ss => <td style={{ ...tableStyle, color: "white" }}>{ss || "\u26AA"}</td>)}
                </tr>)}
                {betsTableRow}
            </tbody>
            <tfoot>
                <tr style={{ ...tableStyle, fontWeight: 'bold' }}>
                    <td style={tableStyle}>Sum</td>
                    {players.map((p, i) => <td key={i} style={tableStyle}>{sum(scores.map(s => s.scores[i]))}</td>)}
                </tr>
            </tfoot>
        </table>
        <div style={{ marginLeft: '250px' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {remotePlayers.map((p, i) => <Player key={p.name} player={p} rotate={-(i * 20 / remotePlayers.length - ((remotePlayers.length - 1) / 2 * 20 / remotePlayers.length))} />)}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                {<div style={{ marginLeft: '50px', fontSize: '150px', color: trump === CardSuit.Diamonds || trump === CardSuit.Hearts ? 'red' : 'black' }}>{trumpSymbols[trump!]}</div>}
            </div>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {reverse(localPlayers).map((p, i) => <Player key={p.name} player={p} rotate={-(i * 20 / localPlayers.length - ((localPlayers.length - 1) / 2 * 20 / localPlayers.length))} />)}
            </div>
        </div>
    </div>)
};

export default Table;