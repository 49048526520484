import { useState } from "react";
import { isMobile, MobileView } from "react-device-detect";
import { useDispatch } from "react-redux";

const Lobby = () => {
    const [gameId, setGameId] = useState<string>("");
    const [playerName, setPlayerName] = useState<string>("");
    const dispatch = useDispatch();

    const handleEnter = () => {
        if (gameId && !isMobile) {
            dispatch({ type: "ENTER_TABLE", gameId });
        } else if (isMobile && gameId && playerName) {
            dispatch({ type: "ENTER_PLAYER", gameId, playerName });
        }
    };

    return (<div className="App">
        Game ID<br/>
        <input title={"Enter game ID"} onChange={e => setGameId(e.target.value)} />
        <MobileView>
            Player name<br/>
            <input title={"Enter name"} onChange={e => setPlayerName(e.target.value)} />
        </MobileView>
        <button onClick={handleEnter}>Enter</button>
    </div>)
};

export default Lobby;